// Config values
$platform-name: "700club";
$assets-path: "../assets/";

// Colour variables
$purple: #640964;
$blue: #0c8cdc;
$pink: #e60bab;
$green-dark: #6d878a;
$green-light: darken(#afc5c5, 5%);

// Colours
$brand-primary: $purple;
$brand-primary-text-contrast-override: null !default;
$brand-secondary: $green-dark;
$brand-secondary-text-contrast-override: null !default;

$donate-colour: $purple;

// Create greyscale
$black: #000;
$grey-dark: #222;
$grey: #444;
$grey-light: #676767;
$grey-lighter: #ECEEEF;
$grey-lightest: #F7F7F7;
$white: #FFFFFF;

// Layout
$container-max-width: 1100px;
$gap-width: 50px;
$site-bleed: 20px;

$border-radius: 2.5px;
$border-colour: $grey-lighter;
$box-shadow: none;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Misc
$body-bg: $white;
$body-colour: $grey-dark;
$profile-pics-enabled: true;
$border-width: 1px;
$date-ordinals-enabled: true;

// Transitions
$transition-duration: 0.1s;
$transition-duration-slow: 0.5s;
$transition-curve: ease;

// Logo
$logo: "logo.svg";
$logo-width: 305px;
$logo-height: 90px;
$logo-absolute: false;
$logo-breakpoint: map-get($breakpoints, md);
$logo-mobile: $logo;
$logo-mobile-width: $logo-width * 0.5;
$logo-mobile-height: $logo-height * 0.5;

// Typography
$font-family-base: 'Lato', sans-serif;
$font-size-base: 1rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;

$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$font-size-h1: 2.75em;
$font-size-h2: 2.5em;
$font-size-h3: 2em;
$font-size-h4: 1.75em;
$font-size-h5: 1.5em;
$font-size-h6: 1.25em;

$headings-margin-top: 0.5em; // This will only affect headings within post content
$headings-margin-bottom: 0.5em;
// $headings-font-family: 'Nanum Gothic', sans-serif;
$headings-font-family: 'Patrick Hand', cursive;
$headings-font-weight: 700;
$headings-line-height: 1.1;
$headings-colour: inherit;
$headings-text-transform: none;
$headings-letter-spacing: none;

$hr-border-colour: $border-colour;
$hr-border-width: $border-width;

// Links
$link-colour: $brand-primary;
$link-decoration: none;
$link-decoration-colour: initial;
$link-hover-decoration: underline;
$link-hover-style: darken;

// Icon font
$icon-font-family: "FontAwesome";

// Buttons
$btn-background-colour: $brand-secondary;
$btn-text-colour: text-contrast($btn-background-colour);
$btn-border-radius: $border-radius;
$btn-border-width: 0;
$btn-border-colour: $btn-background-colour;
$btn-box-shadow: $box-shadow;
$btn-padding-x: 1.5em;
$btn-padding-y: 0.75em;
$btn-font-family: $headings-font-family;
$btn-font-weight: $font-weight-normal;
$btn-font-size: $font-size-large;
$btn-text-transform: uppercase;
$btn-text-decoration: none;
$btn-line-height: 1.25;
$btn-letter-spacing: inherit;
$btn-hover-style: darken;
$btn-transition-duration: $transition-duration;

// Donate button
$donate-btn-background-colour: $donate-colour;
$donate-btn-colour: text-contrast($donate-colour);

// Social icons
$social-icons-background-colour: transparent;
$social-icons-colour: $green-light;
$social-icons-hover-colour: $green-dark;
$social-icons-width: 40px;
$social-icons-height: 40px;
$social-icons-font-size: 1.25rem;
$social-icons-gap: 0;
$social-icons-border-radius: $btn-border-radius;
$social-icons-box-shadow: $btn-box-shadow;

// Header specific
$social-icons-header-background-colour: transparent;
$social-icons-header-colour: $grey-light;
$social-icons-header-hover-colour: $purple;

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
$social-icons-footer-colour: $white;
$social-icons-footer-hover-colour: $green-light;

// Share this page
$share-enabled: true;
$share-text-align: left;
$share-label-font-family: $font-family-base;
$share-label-font-size: $font-size-h4;
$share-label-font-colour: $body-colour;

// Form elements
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-line-height: 1.25;
$input-colour: $body-colour;
$input-border-colour: #ced4da;
$input-border: 1px solid $input-border-colour;
$input-border-radius: $border-radius;
$input-placeholder-colour: $grey-light;

// Field groups
$field-group-label-width: 280px;

// Tables
$table-cell-padding: 0.75rem;

// Card
$card-gap-width: $gap-width;
$card-border-radius: 0;
$card-border: 0;
$card-box-shadow: none;
$card-text-align: left;
$card-image-on: true;
$card-image-opacity: 1;
$card-image-background-colour: $brand-primary;
$card-image-padding: 0;
$card-image-border-radius: 0;
$card-details-padding: $spacer;
$card-details-background-colour: $grey-lightest;
$card-details-background-gradient: none;
$card-heading-font-family: $headings-font-family;
$card-heading-text-transform: $headings-text-transform;
$card-heading-colour: $purple;
$card-heading-font-size: $font-size-h4;
$card-heading-margin-bottom: $spacer / 2;
$card-summary-enabled: true;
$card-summary-colour: rgba($body-colour, 0.7);
$card-summary-font-size: $font-size-base;
$card-footer-on: false;
$card-footer-background-colour: $white;
$card-footer-background-gradient: none;
$card-footer-colour: rgba($body-colour, 0.7);
$card-footer-padding: 0.5rem $card-details-padding;
$card-min-width: 280px;

// Card basic
$card-basic-image-position: above;

// Card text overlay
// $card-text-overlay-show-summary-on-hover: true !default;
// $card-text-overlay-summary-always-on: false !default;
// $card-text-overlay-details-max-width: 100% !default;
// $card-text-overlay-details-position-y: bottom !default; // top, center, bottom
// $card-text-overlay-details-position-x: left !default; // left, center, right
// $card-text-overlay-breakpoint: map-get($breakpoints, sm) !default; // When do you want to break the text under image?

// Card text over
// $card-text-over-image-vertical-text-position: center !default; // top, center or bottom
// $card-text-over-image-darken-image-amount: 0.5 !default;
// $card-text-over-image-tint-colour: $black !default;
// $card-text-over-image-show-summary-on-hover: true !default;

// Card side by side
// $card-side-by-side-image-position: left !default;
// $card-side-by-side-image-width: 50% !default;
// $card-side-by-side-grow-image: true !default; // Do you want the image to scale to 100% height of the overall card?
// $card-side-by-side-vertical-text-position: top !default; // top, center or bottom
// $card-side-by-side-breakpoint: map-get($breakpoints,
//   sm) !default; // When do you want to break the text under image?

// Card hover state
$card-hover-border-colour: $border-colour;
$card-hover-box-shadow: $card-box-shadow;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1;
$card-hover-details-background-colour: $card-details-background-colour;
$card-hover-details-background-gradient: $card-details-background-gradient;
$card-hover-heading-colour: $card-heading-colour;
$card-hover-summary-colour: $body-colour;

// Event cards
$event-card-floating-date: false;
// $event-card-floating-date-background-colour: $card-details-background-colour;
// $event-card-floating-date-colour: text-contrast($event-card-floating-date-background-colour);
// $event-card-floating-date-border-radius: $border-radius;
// $event-card-floating-date-margin-y: $spacer;
// $event-card-floating-date-margin-x: $spacer;

// Global alert
$global-alert-background-colour: $brand-primary;
$global-alert-colour: text-contrast($global-alert-background-colour);

// Menu admin
$menu-admin-enabled: false;
// $menu-admin-max-width: 100%;
// $menu-admin-background-colour: transparent;
// $menu-admin-background-gradient: none;
// $menu-admin-contents-max-width: $container-max-width;
// $menu-admin-font-size: $font-size-small;
// $menu-admin-align-items: right;
// $menu-admin-link-colour: $link-colour;
// $menu-admin-absolute: false;
// $menu-admin-login-link-enabled: false;
// $menu-admin-manager-link-enabled: false;
// $menu-admin-my-details-link-enabled: false;
// $menu-admin-logout-link-enabled: true;
// $menu-admin-to-nav-breakpoint: map-get($breakpoints, lg);

// Header
$header-background-colour: $white;
$header-box-shadow: $box-shadow;
// $header-absolute: false;
// $header-absolute-background: linear-gradient(to bottom, rgba($black, 0.5), rgba($black, 0));
// $header-absolute-breakpoint: map-get($breakpoints, sm);

// Header content
$header-content-max-width: $container-max-width;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: $spacer * 1.5;
$header-content-padding-bottom: $spacer * 1.5;
$header-content-padding-left: $site-bleed;
$header-content-padding-right: $site-bleed;

// Tagline
$tagline-enabled: false;
// $tagline-font-family: $headings-font-family;
// $tagline-font-weight: $font-weight-bold;
// $tagline-font-size: $font-size-h5;
// $tagline-colour: $headings-colour;
// $tagline-above-cta-breakpoint: 0;
// $tagline-breakpoint: map-get($breakpoints, md);

// Main call to action
$cta-btn-background-colour: $btn-background-colour;
$cta-btn-colour: $btn-text-colour;
$cta-btn-gap: 5px;
$cta-btn-breakpoint: map-get($breakpoints, sm);
$cta-donate-on-appeal: false;

// Search
$header-search-enabled: true;
$header-search-margin-right: 10px;
$header-search-breakpoint: map-get($breakpoints, lg);

// Search - input
$header-search-input-max-width: 180px;
$header-search-input-height: initial;
$header-search-input-margin-right: -40px;
$header-search-input-padding: $input-padding-y $input-padding-x;
$header-search-input-placeholder-colour: $input-placeholder-colour;
$header-search-input-border-radius: $border-radius;
$header-search-input-border-colour: $border-colour;
$header-search-input-border-width: .5px .5px .5px .5px;
$header-search-input-background-colour: $grey-lightest;
$header-search-input-colour: text-contrast($header-search-input-background-colour);
$header-search-input-focus-background-colour: $white;
$header-search-input-focus-colour: text-contrast($header-search-input-focus-background-colour);

// Search - button
$header-search-button-width: 40px;
$header-search-button-height: 40px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: $social-icons-font-size;
$header-search-button-icon-colour: $grey-light;
$header-search-button-border-radius: $border-radius;

// Search when in nav
$nav-search-enabled: $header-search-enabled;
$nav-search-input-margin-right: -40px;
$nav-search-input-padding: $input-padding-y $input-padding-x;
$nav-search-input-placeholder-colour: $header-search-input-placeholder-colour;
$nav-search-input-border-colour: $header-search-input-border-colour;
$nav-search-input-border-width: 0;
$nav-search-input-background-colour: $header-search-input-background-colour;
$nav-search-button-background-colour: $header-search-button-background-colour;
$nav-search-button-icon-colour: $grey-light;

// Social icons
$header-social-icons-margin-right: 10px;
$header-social-icons-margin-bottom: 0;
$header-social-icons-above-cta-buttons: false;
$header-social-icons-breakpoint: map-get($breakpoints, lg);


// Sticky header
$header-sticky-enabled: true;
$header-sticky-background-colour: $white;
$header-sticky-box-shadow: 0 0 15px rgba($black, 0.15);
$header-sticky-logo: $logo;
$header-sticky-logo-width: 0;
$header-sticky-logo-height: 0;
$header-sticky-menu-admin-enabled: false;
$header-sticky-search-enabled: false;
$header-sticky-social-icons-enabled: false;
$header-sticky-cta-btns-enabled: false;
$header-sticky-header-content-margin-top: 0;
$header-sticky-header-content-margin-bottom: 0;
$header-sticky-nav-margin-bottom: 0;

//
// Navigation
//

$nav-type: normal;
$nav-font-family: $font-family-base;
$nav-breakpoint: map-get($breakpoints, lg);
$nav-background-colour: $green-dark;
$nav-text-transform: none;

// Top level items
$nav-top-level-item-padding: 12.5px 20px;
$nav-top-level-item-colour: $white;
$nav-top-level-item-font-family: $nav-font-family !default;
$nav-top-level-item-font-size: $font-size-base !default;
$nav-top-level-item-font-weight: 600;
$nav-top-level-item-background-colour: transparent !default;
$nav-top-level-item-hover-colour: $nav-top-level-item-colour !default;
$nav-top-level-item-hover-background-colour: rgba($black, 0.1) !default;
$nav-top-level-chevrons-enabled: false !default;
$nav-top-level-chevrons-colour: $nav-top-level-item-colour !default;
$nav-top-level-first-item-home-icon: true;
$nav-top-level-first-item-home-icon-font-size: 20px;

// Submenus
$nav-submenu-width: 220px;
$nav-submenu-background-colour: $green-light;
$nav-submenu-box-shadow: none;
$nav-submenu-border-radius: $border-radius;
$nav-submenu-item-padding: 10px 20px;
$nav-submenu-item-colour: $white;
$nav-submenu-item-font-family: $nav-font-family;
$nav-submenu-item-font-size: $font-size-base;
$nav-submenu-item-font-weight: 600;
$nav-submenu-item-hover-colour: $nav-submenu-item-colour;
$nav-submenu-item-hover-background-colour: rgba($black, 0.1) !default;
$nav-submenu-chevrons-enabled: true !default;
$nav-submenu-chevrons-colour: $nav-submenu-item-colour !default;

// Burger button
$burger-btn-background-colour: $green-light;
$burger-btn-text-colour: $white;
$burger-btn-text-transform: $btn-text-transform;
$burger-btn-icon-breakpoint: null;
$burger-btn-bar-breakpoint: map-get($breakpoints, sm);

// Nav normal
$nav-normal-max-width: 100%;
$nav-normal-margin-top: 0;
$nav-normal-margin-bottom: 0;
$nav-normal-contents-max-width: $container-max-width !default;
$nav-normal-align-items: center;
$nav-normal-border-radius: 0;
$nav-normal-mobile-background-colour: $green-dark;
$nav-normal-mobile-top-level-item-colour: $white;
$nav-normal-mobile-submenu-background-colour: rgba($black, 0.15);
$nav-normal-mobile-submenu-item-colour: $white;
$nav-normal-mobile-chevrons-enabled: true;
$nav-normal-mobile-chevrons-colour: $white;

// Nav - Parent nav items clickable
$nav-parent-clickable-enabled: false !default;
$nav-parent-clickable-chevron-background-colour: rgba(#fff, 0.75) !default;
$nav-parent-clickable-chevron-colour: $nav-normal-mobile-background-colour !default;
$nav-parent-clickable-chevron-icon-type: "chevron" !default; // can take "chevron" or "plus"
$nav-parent-clickable-chevron-active-background-colour: #fff !default;
$nav-parent-clickable-chevron-active-colour: $nav-parent-clickable-chevron-colour !default;

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-margin-top: 0;
$carousel-margin-bottom: 0;
$carousel-image-overlay: null;
// $carousel-contents-max-width: $container-max-width;
$carousel-contents-max-width: 1440px;
$carousel-breakpoint: map-get($breakpoints, lg);
$carousel-details-style: overlay;
$carousel-details-position-y: center;
$carousel-details-position-x: left;
$carousel-details-background-colour: rgba($white, 0.95);
$carousel-details-box-shadow: $box-shadow;
$carousel-details-max-width: 568px;
$carousel-details-min-height: auto;
$carousel-details-padding: $spacer * 2;
$carousel-details-margin-y: 0px;
$carousel-details-margin-x: #{$site-bleed * 2};
$carousel-details-border-radius: $border-radius;
$carousel-details-text-align: left;
$carousel-heading-text-transform: $headings-text-transform;
$carousel-heading-colour: $green-dark;
$carousel-heading-font-size: $font-size-h2;
$carousel-summary-enabled: true;
$carousel-summary-colour: text-contrast($carousel-details-background-colour);
$carousel-summary-font-size: $font-size-base;
$carousel-read-more-enabled: true;
$carousel-read-more-background-colour: $green-light;
$carousel-read-more-border-colour: $btn-border-colour !default;
$carousel-read-more-colour: $btn-text-colour !default;
$carousel-read-more-font-size: $font-size-small;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: false;
// $carousel-controls-button-size: 40px !default;
// $carousel-controls-button-gap: 0px !default;
// $carousel-controls-button-background-colour: $btn-background-colour !default;
// $carousel-controls-button-border-radius: $border-radius !default;
// $carousel-controls-button-box-shadow: $box-shadow !default;
// $carousel-controls-position-y: bottom !default; // top, center, bottom
// $carousel-controls-position-x: right !default; // split, left or right
// $carousel-controls-margin-y: 0px !default;
// $carousel-controls-margin-x: 0px !default;
// $carousel-controls-icon-colour: $btn-text-colour !default;
// $carousel-controls-icon-font-size: 1em !default;
// $carousel-controls-next-icon: "\f054" !default; // TODO: These are temporarily suspended
// $carousel-controls-prev-icon: "\f053" !default; // TODO: These are temporarily suspended

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: true;
$carousel-buttons-type: dots;

// Carousel buttons dots
$carousel-dots-position-y: bottom;
$carousel-dots-position-x: right;
$carousel-dots-padding: $spacer;
$carousel-dots-colour: $white;
$carousel-dots-opacity: 0.5;
$carousel-dots-active-colour: $green-light;

// Carousel video play button (before breakpoint, when carousel details present)
$carousel-video-btn-position-y: 50%;
$carousel-video-btn-position-x: 66.666%;

// Carousel scroll button
$carousel-scroll-btn-enabled: false;

// Carousel background YouTube video
$carousel-yt-background-enabled: false;
$carousel-yt-background-size: "cover";
$carousel-yt-cover-padding-bottom: 56.25%;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: $green-dark;
$carousel-mobile-heading-colour: $white;
$carousel-mobile-summary-colour: $white;

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios
$banner-min-height: 200px;

// Home intro
$home-intro-max-width: 100%;
$home-intro-background-colour: $grey-lightest;
$home-intro-box-shadow: none;
$home-intro-content-max-width: 725px;
$home-intro-content-align: center;
$home-intro-padding-y: $spacer * 4;
$home-intro-margin-top: 0;
$home-intro-margin-bottom: 0;
$home-intro-text-align: center;
$home-intro-text-transform: none;
$home-intro-font-family: $font-family-base;
$home-intro-font-size: $font-size-large;
$home-intro-font-weight: $font-weight-base;
$home-intro-line-height: $line-height-base;
$home-intro-colour: $body-colour;
$home-intro-border-radius: 0;
$home-intro-mobile-padding-y: $home-intro-padding-y * .7 !default;
$home-intro-mobile-font-size: $home-intro-font-size * .85 !default;

// Home headlines intro
$home-headlines-intro-enabled: false;

// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: $white;
$home-features-padding-top: $spacer * 3;
$home-features-padding-bottom: $spacer * 3;
$home-features-cards-entry-animation-prefab: 0;
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 3;

// Impact stats
$impact-stats-enabled: true;
$impact-stats-max-width: 100%;
$impact-stats-margin-top: 0;
$impact-stats-margin-bottom: 0;
$impact-stats-padding-y: $spacer * 5;
$impact-stats-background-colour: $purple;
$impact-stats-background-gradient: none;
// $impact-stats-background-image: "dimi-katsavaris-N3m2hKNc1j0-unsplash.jpg";
$impact-stats-background-image: "jonathan-rados-Sbxt82CsMxA-unsplash.jpg";
$impact-stats-background-image-opacity: 0.3;
$impact-stats-background-image-size: cover;
$impact-stats-background-image-repeat: no-repeat;
$impact-stats-border-radius: 0;
$impact-stats-colour: $white;
$impact-stats-text-align: center;
$impact-stats-content-max-width: $container-max-width;
$impact-stats-breakpoint: map-get($breakpoints, md);

$impact-stats-overall-container-direction: column;
$impact-stats-direction: row;
$impact-stats-mobile-text-align: $impact-stats-text-align;

// Heading
$impact-stats-heading-enabled: true;
$impact-stats-heading-font-size: $font-size-h1;
$impact-stats-heading-text-align: $impact-stats-text-align;
$impact-stats-heading-colour: inherit;
$impact-stats-heading-margin-bottom: $spacer * 1.5;

// Individual stat
$impact-stat-min-width: 180px;
$impact-stat-direction: column;
$impact-stat-padding: 0px 0px;
$impact-stat-margin: $gap-width;
$impact-stat-border: 0px solid $border-colour;
$impact-stat-border-width: 0 0 0 0;

// Figure
$impact-stats-figure-colour: $impact-stats-colour;
$impact-stats-figure-font-family: $headings-font-family;
$impact-stats-figure-font-size: 4.25em;

// Summary
$impact-stats-summary-colour: inherit;
$impact-stats-summary-font-size: $font-size-large;
$impact-stats-summary-font-weight: $font-weight-base;
$impact-stats-summary-margin-top: $spacer * 0.5;

// Home feeds
$home-feeds-max-width: $container-max-width;
$home-feeds-background-colour: $white;
$home-feeds-padding-top: $spacer * 3;
$home-feeds-padding-bottom: $spacer * 3;
$home-feeds-cards-entry-animation-prefab: 0;
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;

// Feeds title
$feeds-title-font-family: $headings-font-family;
$feeds-title-font-size: $font-size-h2;
$feeds-title-colour: text-contrast($home-feeds-background-colour);
$feeds-title-text-align: center;
$feeds-title-text-transform: $headings-text-transform;

// Feed item
$feed-item-read-more-enabled: false;

// Event feed items
$feed-item-event-start-date-enabled: true;
$feed-item-event-location-enabled: true;

// Twitter feed
$home-feed-twitter-enabled: true;
$home-feed-twitter-card-gap-width: $card-gap-width;
$home-feed-twitter-card-border: $card-border;
$home-feed-twitter-card-border-radius: $card-border-radius;
$home-feed-twitter-card-box-shadow: $card-box-shadow;
$home-feed-twitter-card-text-align: $card-text-align;
$home-feed-twitter-card-padding: $spacer * 1.5;
$home-feed-twitter-card-background-colour: $white;
$home-feed-twitter-card-colour: $body-colour;
$home-feed-twitter-card-min-width: $card-min-width;

// Footer
$footer-boxes-count: 4;
$footer-prefab: 4;
$footer-max-width: 100%;
$footer-contents-max-width: $container-max-width;
$footer-margin-y: 0;
$footer-padding-y: $spacer * 3;
$footer-background-colour: $grey;
$footer-colour: text-contrast($footer-background-colour);
$footer-link-colour: $footer-colour;
$footer-link-decoration: underline;
$footer-link-hover-colour: darken($footer-link-colour, 10%);
$footer-link-hover-decoration: underline;
$footer-text-align: left;
$footer-font-size: $font-size-base;
$footer-headings-font-size: $font-size-h4;

// Footer admin link
$footer-admin-basket-link-enabled: false;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: false;
$footer-admin-my-details-link-enabled: false;
$footer-admin-logout-link-enabled: true;

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-colour: $white;
$newsletter-heading-font-family: $headings-font-family;
$newsletter-heading-font-size: $font-size-h4;
$newsletter-heading-text-align: left;
$newsletter-heading-text-transform: $headings-text-transform;
$newsletter-heading-margin-bottom: $spacer * 1.5;
$newsletter-heading-colour: $newsletter-colour;
$newsletter-input-background-colour: $white;
$newsletter-button-height: 38px;
$newsletter-button-background-colour: $green-light;
$newsletter-button-colour: $white;
$newsletter-button-border-colour: $green-light;
$newsletter-use-placeholders: true;

// Context container
$context-container-background-colour: $grey-lightest;
$context-container-padding: $spacer * 2;
$context-container-border-radius: $border-radius;

// Donors list
$donors-list-enabled: false;
$donors-list-profile-pics-enabled: false;

// Posts
$post-content-max-width: 768px;
$post-body-background-colour: $body-bg;

// Header text (container for breadcrumb and page title)
$header-text-margin-top: $spacer * 3;
$header-text-margin-bottom: $spacer * 3;

// Page title
$page-title-font-size: $font-size-h1;
$page-title-font-family: $headings-font-family;
$page-title-font-weight: $headings-font-weight;
$page-title-colour: $headings-colour;
$page-title-text-transform: $headings-text-transform;
$page-title-text-align: center;
$page-title-margin-top: 0;
$page-title-margin-bottom: $spacer * 2;
$page-title-md-font-size: $page-title-font-size * 0.8 !default; // <= $breakpoints.md
$page-title-sm-font-size: $page-title-font-size * 0.6 !default; // <= $breakpoints.sm

// Page title over banner
$page-title-over-banner-enabled: false;

// Sidebar
$sidebar-enabled: true;
$sidebar-layout: below;
$sidebar-mobile-layout: below;
$sidebar-adjacent-width: 300px;
$sidebar-adjacent-gap: $spacer * 4;
$sidebar-background-colour: transparent;
$sidebar-padding: 0;

// Associated lists
$associated-latest-enabled: true;
$associated-related-enabled: false;
$associated-most-read-enabled: false;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 3;
$associated-list-heading-font-size: $font-size-h3;
$associated-item-image-enabled: false;
$associated-item-heading-font-size: $font-size-base;
$associated-item-heading-colour: $purple;
$associated-item-headings-text-transform: $headings-text-transform;
$associated-item-summary-enabled: true;
$associated-item-border: true;

// Listing
$listing-content-max-width: $container-max-width;
$listing-intro-max-width: $listing-content-max-width;
$listing-body-background-colour: transparent;
$listing-cards-per-row-desktop: 3;
$listing-cards-per-row-tablet: 2;
$listing-card-min-width: $card-min-width;

// Listed post settings
$listed-post-read-more-enabled: false;
$listed-news-post-publish-date-enabled: true;
$listed-blog-post-publish-date-enabled: true;
$listed-blog-post-author-enabled: true;
$listed-event-post-start-date-enabled: true;
$listed-event-post-location-enabled: true;
$listed-appeal-totaliser-enabled: true;

// Breadcrumb
$breadcrumb-enabled: true;
$breadcrumb-disabled-on-listing: true;
$breadcrumb-margin-top: $spacer;
$breadcrumb-margin-bottom: $spacer;
$breadcrumb-max-width: $post-content-max-width;
$breadcrumb-text-align: center;

// Blockquote
$blockquote-max-width: $post-content-max-width;
$blockquote-background-colour: $grey-lightest;
$blockquote-border-radius: $border-radius;
$blockquote-margin-y: $spacer * 3;
$blockquote-padding: $spacer*2 $spacer*2 $spacer*2 $spacer*3;
$blockquote-text-align: left;
$blockquote-font-size: $font-size-h4;
$blockquote-font-family: $headings-font-family;
$blockquote-font-weight: $headings-font-weight;
$blockquote-line-height: 1.35;
$blockquote-colour: $body-colour;
$blockquote-box-shadow: none;

// Fundraising
$fundraising-enabled: true;

// Find a fundraiser widget
$find-a-fundraiser-widget-enabled: true;
$find-a-fundraiser-profile-pics-enabled: false;

// Top fundraisers
$top-fundraisers-enabled: false;
$top-fundraisers-profile-pics-enabled: false;

// Donation form
$donation-form-layout: columns;
$donation-form-banner-enabled: false;
$donation-form-frequency-tabs: false;

// Donation amounts
$donation-amount-min-width: 220px;
$donation-amount-figure-font-size: $font-size-h2;
$donation-amount-figure-colour: $donate-colour;
$donation-amount-thumbnail-enabled: true;

// Quick giving panel
$quick-giving-type: simple;
$quick-giving-background-colour: $green-light;
$quick-giving-padding: $context-container-padding;
$quick-giving-border-radius: $border-radius;
$quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size * 0.75;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: $white;
$quick-giving-donation-amount-figure-colour: $body-colour;
$quick-giving-donation-amount-border-width: 0;
$quick-giving-donation-amount-border-colour: $white;
$quick-giving-donation-amount-selected-background-colour: $white;
$quick-giving-donation-amount-selected-figure-colour: $donate-colour;

// Home quick giving panel
$home-quick-giving-enabled: true;
$home-quick-giving-type: simple;
$home-quick-giving-max-width: 100%;
$home-quick-giving-content-max-width: 100%;
$home-quick-giving-margin-top: 0;
$home-quick-giving-margin-bottom: 0;
$home-quick-giving-padding-y: $spacer * 3.5;
$home-quick-giving-background-colour: $green-light;
$home-quick-giving-donation-amount-background-colour: $white;
$home-quick-giving-donation-amount-figure-colour: $body-colour;
$home-quick-giving-donation-amount-border-width: 0;
$home-quick-giving-donation-amount-border-colour: $white;
$home-quick-giving-donation-amount-selected-background-colour: $white;
$home-quick-giving-donation-amount-selected-figure-colour: $donate-colour;
$home-quick-giving-donate-btn-background-colour: $donate-colour;
$home-quick-giving-donate-btn-colour: $btn-text-colour;
$home-quick-giving-donate-btn-border-colour: $donate-colour;
$home-quick-giving-donate-btn-font-size: $quick-giving-donation-amount-figure-font-size;
$home-quick-giving-colour: $white;
$home-quick-giving-heading-colour: $white;
$home-quick-giving-heading-font-size: $font-size-h2;

// Button actions
$button-actions-padding: 0;
$button-actions-background-colour: transparent;
$button-actions-align-buttons: center;

// Totalisers
$totaliser-enabled: true;
$totaliser-layout: horizontal;
$totaliser-width: 60px;
$totaliser-panel-background-colour: transparent;

// Comments
$comment-add-image-enabled: false;
$comment-add-video-enabled: false;

// Blog details$blog-details-enabled: true !default;
$blog-details-background-colour: $context-container-background-colour !default;
$blog-details-padding: $spacer;
$blog-details-border-radius: $border-radius;
$blog-details-profile-pic-enabled: $profile-pics-enabled;

// FAQs
$faq-content-max-width: $post-content-max-width;
$faq-item-header-padding: $context-container-padding;
$faq-item-header-border-radius: $border-radius;
$faq-item-header-font-size: $font-size-h4;
$faq-item-btn-background-colour: $purple;
$faq-item-btn-chevron-colour: $white;
$faq-item-hover-btn-background-colour: $purple;
$faq-item-hover-btn-chevron-colour: $white;

// Cookie consent
$cookie-consent-position: bottom;
$cookie-consent-background-colour: rgba($grey-dark, 0.95);
$cookie-consent-colour: $white;
$cookie-consent-font-family: $font-family-base;
$cookie-consent-btn-background-colour: $green-dark;
$cookie-consent-btn-text-colour: $white;

// Keeping In Touch
$keeping-in-touch-enabled: true !default;
$keeping-in-touch-via-email-enabled: true !default;
$keeping-in-touch-via-telephone-enabled: true !default;
$keeping-in-touch-via-sms-enabled: true !default;
$keeping-in-touch-via-post-enabled: true !default;

// Shop
$shop-enabled: false;